import React, { useContext, useEffect, useState } from "react"; 
import { Link } from "react-router-dom"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import { faArrowRightFromBracket, faFile, faHome, faLock, faToolbox, faUserNinja } from "@fortawesome/free-solid-svg-icons"; 
import { BlurContext } from "../../context/BlurContext"; 
import { SyncReportsContext } from "../../context/SyncReportsProvider"; 
import "./Links.css"; 
// import Student Enrollment Scenarios
// import myPdf from "../StudentEnrollmentScenarios.pdf";

// Components
import DownloadReportDropdown from "../DownloadReportDropdown/DownloadReportDropdown"; 
import HelpLink from "./HelpLink";
import ChangePassword from "../ChangePassword/ChangePassword"; 

const Links = () => {
  const [username, setUsername] = useState("");
  const { isBlurred, setIsBlurred } = useContext(BlurContext);
  const { userAd, crosscheckAdmin } = useContext(SyncReportsContext);
  const blurClass = isBlurred ? "blur" : "";

  useEffect(() => {
    const savedDisplayName = localStorage.getItem("display_name");
    if (savedDisplayName) {
      setUsername(savedDisplayName);
    }
  }, []);

  const toggleBlur = () => setIsBlurred(!isBlurred);

  return (
    <div className="header-container right-header">
      <div className="welcome-message">
        Welcome, <span className={blurClass}>{username}</span>
      </div>
      <div className="links">
        <HomeLink />
        <button onClick={toggleBlur} className="blur-toggle" title="Blur Data">
          <FontAwesomeIcon icon={faUserNinja} />
        </button>
        {crosscheckAdmin && <AdminLink />}
        {!crosscheckAdmin && !userAd && <UpdatePasswordLink />}
        <DownloadReportDropdown />
        <HelpLink />
        {/* <ChatLink /> */}
        {userAd && <ReportListLink />}
        <LogoutLink />
      </div>
    </div>
  );
};

const ReportListLink = () => (
  <Link to="/reportlist" title=" Master Report List Information">
    <FontAwesomeIcon icon={faFile} />
  </Link>
);


const AdminLink = () => (
  <a href="/admin" target="_blank" rel="noopener noreferrer" title="Admin Page">
    <FontAwesomeIcon icon={faToolbox} />
  </a>
);

const HomeLink = () => (
  <Link to="/" title="Welcome">
    <FontAwesomeIcon icon={faHome} />
  </Link>
);

const LogoutLink = () => (
  <Link to="logout" title="Sign Off">
    <FontAwesomeIcon icon={faArrowRightFromBracket} />
  </Link>
);

// const ChatLink = () => (
//   <Link to="chat" title="Chat">
//     <FontAwesomeIcon icon={faFilePdf} />
//   </Link>
// );

const UpdatePasswordLink = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <FontAwesomeIcon
        icon={faLock}
        className="fa-lock"
        onClick={handleShow}
        title="Change Password"
      />
      <ChangePassword show={show} handleClose={handleClose} />
    </>
  );
};

export default Links;

import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

// Context imports
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import { useDistrict } from "../Breadcrumbs/Breadcrumbs";

// Data table import
import DataTable from "react-data-table-component";
import { InputGroup, FormControl } from "react-bootstrap";

// Component imports
import Blur from "../Blur/Blur";
import ErrorModal from "../ErrorModal/ErrorModal";
import Extract from "../Extract/Extract";
import MoreInfo from "../MoreInfo/MoreInfo";
import ReportLegend from "../ReportLegend/ReportLegend";
import PrintReport from "../PrintReport/PrintReport";

// FontAwesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faInfo,
  faSearch,
  faSpinner,
  faTimes,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

// Utility imports
import { formatDate, isDate } from "../../hooks/dateUtils";

// Bootstrap imports
import { Button, Card } from "react-bootstrap";

// Style import
import "./DataTable.css";

// Axios import
import axios from "../../api/axios";

function MainDataTable() {
  // eslint-disable-next-line no-unused-vars
  const [activeRowId, setActiveRowId] = useState(null);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [extractRequested, setExtractRequested] = useState(false);
  const [filterText, setFilterText] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [report, setReport] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [title, setTitle] = useState("");
  const [updatingReports] = useState(false);
  const [, setSearchTerm] = useState("");
  const [loadingReviewId, setLoadingReviewId] = useState(null);

  // useParam hook to get the reportId from the URL
  const { reportId } = useParams();

  const dataTableRef = useRef(null); // Ref for the DataTable component

  // Context hooks
  const { reports, selectedYear, runReport } = useContext(SyncReportsContext);

  const { selectedDistrict } = useDistrict();
  const debounceRef = useRef(null); // Debounce timer

  useEffect(() => {
    const resetState = () => {
      setData([]);
      setReport(null);
      setTitle("");
      setShowErrorModal(false);
      setErrorMessage("");
      setSearchTerm("");
      setFilterText("");
    };

    const fetchAndSetData = async () => {
      const reportData = reports[reportId];
      if (!reportData) {
        console.log(`No data available for reportId: ${reportId}`);
        resetState();
        return;
      }

      const transformedData = reportData.data.map((item) => ({
        ...item.fields,
        reviewed: item.reviewed || { reviewed: false, note: "" },
      }));

      setData(transformedData);
      setColumns(
        createColumns(
          reportData.report.columns.filter(
            (column) => column.title !== "Building Name"
          )
        )
      );
      setTitle(reportData.report.title);
      setReport(reportData.report);
    };

    if (reportId && Object.keys(reports).includes(reportId)) {
      fetchAndSetData();
    } else {
      console.log("Invalid or missing reportId:", reportId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, reports, report]);

  const handleAnyReviewChange = async (row, newReviewedStatus, newNote) => {
    setActiveRowId(row.reviewed.pk_value);

    // Optimistically update the UI
    const updatedData = data.map((item) =>
      item.pk_value === row.reviewed.pk_value
        ? {
            ...item,
            reviewed: {
              ...item.reviewed,
              reviewed: newReviewedStatus,
              note: newNote,
            },
          }
        : item
    );
    setData(updatedData);

    // API request preparation
    const reviewData = {
      district_irn: selectedDistrict.irn,
      report_id: reportId,
      pk_value: row.reviewed.pk_value,
      note: newNote,
      year: selectedYear,
      deleted: 0,
      status: newReviewedStatus ? 1 : 0,
    };

    // Show loading spinner for this row
    setLoadingReviewId(row.reviewed.pk_value);

    // Debounce API call and runReport
    if (debounceRef.current) {
      clearTimeout(debounceRef.current); // Clear previous debounce timer
    }

    debounceRef.current = setTimeout(async () => {
      try {
        if (row.reviewed.review_id) {
          await axios.put(`/cc/review/${row.reviewed.review_id}`, reviewData);
        } else {
          const response = await axios.post(`/cc/review`, reviewData);
          setData((currentData) =>
            currentData.map((item) =>
              item.pk_value === row.reviewed.pk_value
                ? {
                    ...item,
                    reviewed: {
                      ...item.reviewed,
                      review_id: response.data.review_id,
                    },
                  }
                : item
            )
          );
        }

        // Call runReport after the debounce
        if (
          report &&
          selectedDistrict &&
          selectedDistrict.irn &&
          selectedYear
        ) {
          await runReport(report, selectedDistrict.irn, selectedYear);
        }
      } catch (error) {
        console.error("Failed to update review: ", error);
        handleError(error);
      } finally {
        setLoadingReviewId(null); // Remove loading spinner
        setActiveRowId(null);
      }
    }, 500); // Debounce delay
  };

  const createColumns = (reportColumns) => {
    const dynamicColumns = reportColumns
      .filter((column) => !column.export_only)
      .map((column) => ({
        name: (
          <div className="column-header">
            {column.badge && (
              <span className="badge badge-dark">{column.badge}</span>
            )}
            <div>{column.title}</div>
          </div>
        ),
        selector: (row) => row[column.data_col_name], // Updated to function
        sortable: true,
        // width: '200px',
        grow: 1,
        cell: (row) => (
          <Blur columnKey={column.data_col_name}>
            {isDate(row[column.data_col_name])
              ? formatDate(row[column.data_col_name])
              : row[column.data_col_name]}
          </Blur>
        ),
      }));

    // Additional column for reviews
    dynamicColumns.push({
      name: "Review",
      selector: (row) => row.reviewed.reviewed, // Ensure this selector is also a function
      width: "200px", 
      grow: 2,
      sortable: true,
      cell: (row, index) => (
        <div className="comment-div" key={`review-${row.pk_value}`}>
          <Button
            variant={row.reviewed.reviewed ? "success" : "warning"}
            onClick={() =>
              handleAnyReviewChange(
                row,
                !row.reviewed.reviewed,
                row.reviewed.note
              )
            }
          >
            {loadingReviewId === row.reviewed.pk_value ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : row.reviewed.reviewed ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <FontAwesomeIcon icon={faTriangleExclamation} />
            )}
          </Button>

          <textarea
            className="comment-box"
            value={row.reviewed.note || ""}
            onBlur={() =>
              handleAnyReviewChange(
                row,
                row.reviewed.reviewed,
                row.reviewed.note
              )
            }
            onChange={(e) =>
              setData((currentData) =>
                currentData.map((item) =>
                  item === row
                    ? {
                        ...item,
                        reviewed: { ...item.reviewed, note: e.target.value },
                      }
                    : item
                )
              )
            }
          />
        </div>
      ),
    });

    return dynamicColumns;
  };

  // End createColumns

  // Helper functions:
  const filteredData = data.filter((item) => {
    return Object.values(item).some((value) => {
      if (value === null) {
        return false;
      }
      return value.toString().toLowerCase().includes(filterText.toLowerCase());
    });
  });

  const handleError = (error) => {
    const errorCode = error.code;
    const errorMessage = error.message || error.response?.data?.message;
    setErrorCode(errorCode);
    setErrorMessage(errorMessage);
    setShowErrorModal(true);
  };

  const extractData = async () => {
    if (updatingReports) {
      // If updates are in progress, mark extract as requested and wait
      setExtractRequested(true);
    } else {
      // Proceed with extraction as updates are not in progress
      <Extract data={filteredData.map((item) => Object.values(item))} />;

      setExtractRequested(false); // Reset the request flag after extraction
    }
  };

  const handleClearSearch = () => {
    setFilterText(""); // Clear the filter text
    setSearchTerm(""); // Clear the searchTerm
    const searchInput = document.getElementById("search-input"); // Get the search input element
    if (searchInput) {
      searchInput.value = ""; // Clear the value of the search input field
    }
  };

  useEffect(() => {
    if (!updatingReports && extractRequested) {
      // Proceed with extraction after updates are complete
      extractData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatingReports, extractRequested]);
  // End helper functions

  return (
    <div className="table-container">
      <MoreInfo
        show={showMoreInfo}
        onHide={() => setShowMoreInfo(false)}
        handleClose={() => setShowMoreInfo(false)}
        moreInfoContent={
          report && report.more_info
            ? report.more_info
            : "No information available for this report."
        }
      />

      <ErrorModal
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
        errorMessage={errorMessage}
      />

      <Card className="reportCard">
        <div className="button-container">
          <Button
            variant="info"
            className="reportInfoBtn"
            onClick={() => setShowMoreInfo(true)}
            title="More Information"
          >
            <FontAwesomeIcon icon={faInfo} />
          </Button>

          <PrintReport reportId={reportId} />

          <Extract data={filteredData.map((item) => Object.values(item))} />

          <div className="search-container">
            <InputGroup className="mb-3-datatable">
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <FormControl
                id="search-input"
                placeholder="Search reports..."
                aria-label="Search reports"
                aria-describedby="search-addon"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className="reportSearchInput"
              />
              {filterText && (
                <InputGroup.Text
                  id="clear-search-addon"
                  onClick={handleClearSearch}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </InputGroup.Text>
              )}
            </InputGroup>
          </div>
          <footer className="record-count">
            {filteredData.length} of {data.length} records
          </footer>
        </div>

        <div ref={dataTableRef}>
          <DataTable
            className="data-table"
            title={<div className="data-table-title">{title}</div>}
            columns={columns}
            data={filteredData}
            customStyles={customStyles}
            highlightOnHover={true}
            pagination
            paginationPerPage={25}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            fixedHeader
          />
        </div>
      </Card>
      <ReportLegend />
    </div>
  );
}

/* Custom styles for cells, rows, and head cells */
const customStyles = {
  headCells: {
    style: {
      backgroundColor: "lightgray",
      fontWeight: "700",
      border: ".25px solid #dee2e6",
      padding: "3px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  rows: {
    style: {
      "&:nth-child(odd)": {
        backgroundColor: "#f8f9fa",
      },
      "&:nth-child(even)": {
        backgroundColor: "#ffffff",
      },
    },
  },
  cells: {
    style: {
      border: ".5px solid #dee2e6",
      fontWeight: "500",
      padding: "3px",
      // display: "flex",
      // justifyContent: "center",
      // alignItems: "center",
    },
  },
};

export default MainDataTable;

// // Master Code
// import React, { useContext, useEffect, useState, useRef } from "react";
// import { useParams } from "react-router-dom";

// // Context imports
// import { SyncReportsContext } from "../../context/SyncReportsProvider";
// import { useDistrict } from "../Breadcrumbs/Breadcrumbs";

// // Data table import
// import DataTable from "react-data-table-component";
// import { InputGroup, FormControl } from "react-bootstrap";

// // Component imports
// import Blur from "../Blur/Blur";
// import ErrorModal from "../ErrorModal/ErrorModal";
// import Extract from "../Extract/Extract";
// import MoreInfo from "../MoreInfo/MoreInfo";
// import ReportLegend from "../ReportLegend/ReportLegend";
// import PrintReport from "../PrintReport/PrintReport";

// // FontAwesome icons
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheck, faInfo, faSearch, faSpinner, faTimes, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

// // Utility imports
// import { formatDate, isDate } from "../../hooks/dateUtils";

// // Bootstrap imports
// import { Button, Card } from "react-bootstrap";

// // Style import
// import "./DataTable.css";

// // Axios import
// import axios from "../../api/axios";

// function MainDataTable() {
//   const [activeRowId, setActiveRowId] = useState(null);
//   const [columns, setColumns] = useState([]);
//   const [data, setData] = useState([]);
//   const [, setErrorCode] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");
//   const [extractRequested, setExtractRequested] = useState(false);
//   const [filterText, setFilterText] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [report, setReport] = useState(null);
//   const [showErrorModal, setShowErrorModal] = useState(false);
//   const [showMoreInfo, setShowMoreInfo] = useState(false);
//   const [title, setTitle] = useState("");
//   const [updatingReports] = useState(false);
//   const [, setSearchTerm] = useState("");

//   // useParam hook to get the reportId from the URL
//   const { reportId } = useParams();

//   const dataTableRef = useRef(null); // Ref for the DataTable component

//   // Context hooks
//   const { reports, selectedYear, runReport } = useContext(SyncReportsContext);

//   const { selectedDistrict } = useDistrict();

//   useEffect(() => {
//     const resetState = () => {
//       setData([]);
//       setReport(null);
//       setTitle("");
//       setShowErrorModal(false);
//       setErrorMessage("");
//       setSearchTerm("");
//       setFilterText("");
//     };

//     const fetchAndSetData = async () => {
//       const reportData = reports[reportId];
//       if (!reportData) {
//         console.log(`No data available for reportId: ${reportId}`);
//         resetState();
//         return;
//       }

//       const transformedData = reportData.data.map((item) => ({
//         ...item.fields,
//         reviewed: item.reviewed || { reviewed: false, note: "" },
//       }));

//       setData(transformedData);
//       setColumns(
//         createColumns(
//           reportData.report.columns.filter(
//             (column) => column.title !== "Building Name"
//           )
//         )
//       );
//       setTitle(reportData.report.title);
//       setReport(reportData.report);
//     };

//     if (reportId && Object.keys(reports).includes(reportId)) {
//       fetchAndSetData();
//     } else {
//       console.log("Invalid or missing reportId:", reportId);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [reportId, reports, report]);

//   const handleAnyReviewChange = async (row, newReviewedStatus, newNote) => {
//     setIsLoading(true);
//     setActiveRowId(row.reviewed.pk_value);

//     const updatedData = data.map((item) =>
//       item.pk_value === row.reviewed.pk_value
//         ? {
//             ...item,
//             reviewed: {
//               ...item.reviewed,
//               reviewed: newReviewedStatus,
//               note: newNote,
//             },
//           }
//         : item
//     );
//     setData(updatedData);

//     try {
//       let response;
//       const reviewData = {
//         district_irn: selectedDistrict.irn,
//         report_id: reportId,
//         pk_value: row.reviewed.pk_value,
//         note: newNote,
//         year: selectedYear,
//         deleted: 0,
//         status: newReviewedStatus ? 1 : 0,
//       };

//       if (row.reviewed.review_id) {
//         response = await axios.put(
//           `/cc/review/${row.reviewed.review_id}`,
//           reviewData
//         );
//       } else {
//         response = await axios.post(`/cc/review`, reviewData);
//       }

//       // Update the review_id if a new one is received
//       if (!row.reviewed.review_id) {
//         setData((currentData) =>
//           currentData.map((item) =>
//             item.pk_value === row.reviewed.pk_value
//               ? {
//                   ...item,
//                   reviewed: {
//                     ...item.reviewed,
//                     review_id: response.data.review_id,
//                   },
//                 }
//               : item
//           )
//         );
//       }

//       if (report && selectedDistrict && selectedDistrict.irn && selectedYear) {
//         await runReport(report, selectedDistrict.irn, selectedYear);
//       } else {
//         console.error("Invalid arguments for runReport:", {
//           report,
//           districtIRN: selectedDistrict?.irn,
//           selectedYear,
//         });
//       }
//     } catch (error) {
//       console.error("Failed to update review: ", error);
//       setData(data); // Revert to the data state before the attempt
//       handleError(error);
//     } finally {
//       setIsLoading(false);
//       setActiveRowId(null);
//     }
//   };

//   const createColumns = (reportColumns) => {
//     const dynamicColumns = reportColumns
//       .filter((column) => !column.export_only)
//       .map((column) => ({
//         name: (
//           <div className="column-header">
//             {column.badge && (
//               <span className="badge badge-dark">{column.badge}</span>
//             )}
//             <div>{column.title}</div>
//           </div>
//         ),
//         selector: (row) => row[column.data_col_name], // Updated to function
//         sortable: true,
//         cell: (row) => (
//           <Blur columnKey={column.data_col_name}>
//             {isDate(row[column.data_col_name])
//               ? formatDate(row[column.data_col_name])
//               : row[column.data_col_name]}
//           </Blur>
//         ),
//       }));

//     // Additional column for reviews
//     dynamicColumns.push({
//       name: "Review",
//       selector: (row) => row.reviewed.reviewed, // Ensure this selector is also a function
//       grow: 2,
//       sortable: true,
//       cell: (row, index) => (
//         <div className="comment-div" key={`review-${row.pk_value}`}>
//           <Button
//             variant={row.reviewed.reviewed ? "success" : "warning"}
//             onClick={() =>
//               handleAnyReviewChange(
//                 row,
//                 !row.reviewed.reviewed,
//                 row.reviewed.note
//               )
//             }
//           >
//             {isLoading && activeRowId === row.reviewed.pk_value ? (
//               <FontAwesomeIcon icon={faSpinner} spin />
//             ) : row.reviewed.reviewed ? (
//               <FontAwesomeIcon icon={faCheck} />
//             ) : (
//               <FontAwesomeIcon icon={faTriangleExclamation} />
//             )}
//           </Button>
//           <textarea
//             className="comment-box"
//             value={row.reviewed.note || ""}
//             onBlur={() =>
//               handleAnyReviewChange(
//                 row,
//                 row.reviewed.reviewed,
//                 row.reviewed.note
//               )
//             }
//             onChange={(e) =>
//               setData((currentData) =>
//                 currentData.map((item) =>
//                   item === row
//                     ? {
//                         ...item,
//                         reviewed: { ...item.reviewed, note: e.target.value },
//                       }
//                     : item
//                 )
//               )
//             }
//           />
//         </div>
//       ),
//     });

//     return dynamicColumns;
//   };

//   // End createColumns

//   // Helper functions:
//   const filteredData = data.filter((item) => {
//     return Object.values(item).some((value) => {
//       if (value === null) {
//         return false;
//       }
//       return value.toString().toLowerCase().includes(filterText.toLowerCase());
//     });
//   });

//   const handleError = (error) => {
//     const errorCode = error.code;
//     const errorMessage = error.message || error.response?.data?.message;
//     setErrorCode(errorCode);
//     setErrorMessage(errorMessage);
//     setShowErrorModal(true);
//   };

//   const extractData = async () => {
//     if (updatingReports) {
//       // If updates are in progress, mark extract as requested and wait
//       setExtractRequested(true);
//     } else {
//       // Proceed with extraction as updates are not in progress
//       <Extract data={filteredData.map((item) => Object.values(item))} />;

//       setExtractRequested(false); // Reset the request flag after extraction
//     }
//   };

//   const handleClearSearch = () => {
//     setFilterText(""); // Clear the filter text
//     setSearchTerm(""); // Clear the searchTerm
//     const searchInput = document.getElementById("search-input"); // Get the search input element
//     if (searchInput) {
//       searchInput.value = ""; // Clear the value of the search input field
//     }
//   };

//   useEffect(() => {
//     if (!updatingReports && extractRequested) {
//       // Proceed with extraction after updates are complete
//       extractData();
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [updatingReports, extractRequested]);
//   // End helper functions

//   return (
//     <div className="table-container">
//       <MoreInfo
//         show={showMoreInfo}
//         onHide={() => setShowMoreInfo(false)}
//         handleClose={() => setShowMoreInfo(false)}
//         moreInfoContent={
//           report && report.more_info
//             ? report.more_info
//             : "No information available for this report."
//         }
//       />

//       <ErrorModal
//         show={showErrorModal}
//         onHide={() => setShowErrorModal(false)}
//         errorMessage={errorMessage}
//       />

//       <Card className="reportCard">
//         <div className="button-container">
//           <Button
//             variant="info"
//             className="reportInfoBtn"
//             onClick={() => setShowMoreInfo(true)}
//             title="More Information"
//           >
//             <FontAwesomeIcon icon={faInfo} />
//           </Button>

//           <PrintReport reportId={reportId} />

//           <Extract data={filteredData.map((item) => Object.values(item))} />

//           <div className="search-container">
//             <InputGroup className="mb-3-datatable">
//             <InputGroup.Text>
//                 <FontAwesomeIcon icon={faSearch} />
//               </InputGroup.Text>
//               <FormControl
//                 id="search-input"
//                 placeholder="Search reports..."
//                 aria-label="Search reports"
//                 aria-describedby="search-addon"
//                 value={filterText}
//                 onChange={(e) => setFilterText(e.target.value)}
//                 className="reportSearchInput"
//               />
//               {filterText && (
//                 <InputGroup.Text
//                   id="clear-search-addon"
//                   onClick={handleClearSearch}
//                   style={{ cursor: "pointer" }}
//                 >
//                   <FontAwesomeIcon icon={faTimes} />
//                 </InputGroup.Text>
//               )}
//             </InputGroup>
//           </div>
//           <footer className="record-count">
//             {filteredData.length} of {data.length} records
//           </footer>
//         </div>

//         <div ref={dataTableRef}>
//           <DataTable
//             className="data-table"
//             title={<div className="data-table-title">{title}</div>}
//             columns={columns}
//             data={filteredData}
//             customStyles={customStyles}
//             highlightOnHover={true}
//             pagination
//             paginationPerPage={25}
//             paginationRowsPerPageOptions={[10, 25, 50, 100]}
//             fixedHeader
//           />
//         </div>
//       </Card>
//       <ReportLegend />
//     </div>
//   );
// }

// /* Custom styles for cells, rows, and head cells */
// const customStyles = {
//   headCells: {
//     style: {
//       backgroundColor: "lightgray",
//       fontWeight: "700",
//       border: ".25px solid #dee2e6",
//       padding: "3px",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",

//     },
//   },
//   rows: {
//     style: {
//       "&:nth-child(odd)": {
//         backgroundColor: "#f8f9fa",
//       },
//       "&:nth-child(even)": {
//         backgroundColor: "#ffffff",
//       },
//     },
//   },
//   cells: {
//     style: {
//       border: ".5px solid #dee2e6",
//       fontWeight: "500",
//       padding: "3px",
//     },
//   },
// };

// export default MainDataTable;

import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Tooltip,
  OverlayTrigger,
  Button,
  Spinner,
  FormControl,
  InputGroup,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCheck,
  faFilter,
  faUserPlus,
  faTrash,
  faTimes,
  faFileExport,
  faSearch,
  faToggleOn,
  faToggleOff,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import axios from "../../api/axios";
import ChangePassword from "../ChangePassword/ChangePassword";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import { UserManagementContext } from "../../context/UserManagementProvider";
import { useDistrict } from "../Breadcrumbs/Breadcrumbs";
import "./UserManagement.css";
import Blur from "../Blur/Blur";

const UserManagement = () => {
  // eslint-disable-next-line
  const { selectUser, cleanUpTitle, categoryStatusByUser, deleteUser } =
    useContext(UserManagementContext);
  const { selectedDistrict } = useDistrict();
  const {
    userRole,
    menu,
    userAd,
    crosscheckAdmin,
    submitter,
    itcSifAdministrator,
  } = useContext(SyncReportsContext);
  const navigate = useNavigate();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAccessColumns, setShowAccessColumns] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isSidenavOpen, setIsSidenavOpen] = useState(false);
  const [isSidenavExpanded, setIsSidenavExpanded] = useState(false);

  // Handle sidenav toggle
  const handleSidenavToggle = () => {
    setIsSidenavOpen(!isSidenavOpen);
  };

  // Handle sidenav expand
  const handleSidenavExpand = () => {
    setIsSidenavExpanded(!isSidenavExpanded);
  };

  // Add event listeners for sidenav changes
  useEffect(() => {
    const sidenavElement = document.getElementById("sidenav");
    if (sidenavElement) {
      sidenavElement.addEventListener("toggle", handleSidenavToggle);
      sidenavElement.addEventListener("expand", handleSidenavExpand);
    }

    // Cleanup on unmount
    return () => {
      if (sidenavElement) {
        sidenavElement.removeEventListener("toggle", handleSidenavToggle);
        sidenavElement.removeEventListener("expand", handleSidenavExpand);
      }
    };
    // eslint-disable-next-line
  }, []);

  // Fetch users from API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/cc/users/${selectedDistrict.irn}`);
        setUsers(response.data.users);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, [selectedDistrict.irn]);

  const handleClose = useCallback(() => setShowChangePasswordModal(false), []);
  const handleEditUser = useCallback(
    (user) => {
      selectUser(user);
      navigate("/edituser");
    },
    [navigate, selectUser]
  );

  const handleDeleteUser = useCallback(
    async (user) => {
      const confirmed = window.confirm(
        "Are you sure you want to delete this user? This action will remove the user from the system but will not affect their ITC Active Directory account. Do you wish to proceed?"
      );
      if (confirmed) {
        try {
          await deleteUser(user);
          const response = await axios.get(`/cc/users/${selectedDistrict.irn}`);
          setUsers(response.data.users);
        } catch (err) {
          console.error(err);
        }
      }
    },
    [deleteUser, selectedDistrict.irn]
  );

  const getReportAccessIcon = (user, category) => {
    const accessLevel = categoryStatusByUser(category, user);
    const iconProps = {
      full: { icon: faCheck, color: "green" },
      partial: { icon: faFilter, color: "#33A1FD" },
      none: { icon: faBan, color: "red" },
    }[accessLevel || "none"];
    return (
      <FontAwesomeIcon
        icon={iconProps.icon}
        style={{
          color: iconProps.color,
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
    );
  };

  const canShowAddUserButton = useMemo(
    () => userAd && (itcSifAdministrator || crosscheckAdmin || submitter),
    [userAd, itcSifAdministrator, crosscheckAdmin, submitter]
  );

  // Search logic with date matching
  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      const term = searchTerm.toLowerCase();
      const dateMatches =
        user.last_login &&
        new Date(user.last_login).toLocaleDateString().includes(term);

      return (
        user.first_name?.toLowerCase().includes(term) ||
        user.last_name?.toLowerCase().includes(term) ||
        user.username?.toLowerCase().includes(term) ||
        user.email?.toLowerCase().includes(term) ||
        dateMatches
      );
    });
  }, [users, searchTerm]);

  const handleClearSearch = () => setSearchTerm("");

  const getTimeStamp = () => new Date().toLocaleString().replace(/[^\w]/g, "_");

  const exportToCSV = () => {
    const csvContent = `First Name,Last Name,Username,AD Account,Last Login\n${users
      .map(
        (user) =>
          `${user.first_name},${user.last_name},${user.username},${
            user.ad_account
          },${
            user.last_login
              ? new Date(user.last_login).toLocaleDateString()
              : "Never"
          }`
      )
      .join("\n")}`;
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${selectedDistrict.name}_Users_List_${getTimeStamp()}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up
  };

  const accessSortFunction = (a, b, category) => {
    const accessOrder = { full: 1, partial: 2, none: 3 };
    const aAccess = categoryStatusByUser(category, a) || "none";
    const bAccess = categoryStatusByUser(category, b) || "none";
    return accessOrder[aAccess] - accessOrder[bAccess];
  };

  const customStyles = {
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        textAlign: "center",
        padding: "10px",
        whiteSpace: "nowrap",
        wordWrap: "break-word",
        borderBottom: "2px solid var(--border-color)",
        borderRight: "1px solid var(--border-color)",
      },
    },
    rows: {
      style: {
        minHeight: "40px",
        textAlign: "center",
        borderBottom: "1px solid var(--border-color)",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
        textAlign: "center",
        whiteSpace: "normal",
        wordWrap: "break-word",
        padding: "8px 12px",
        borderRight: "1px solid var(--border-color)",
      },
    },
  };

  // Define columns for DataTable
  const columns = [
    {
      name: (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>User's full name</Tooltip>}
        >
          <span className="umNameHeader">Name</span>
        </OverlayTrigger>
      ),
      selector: (user) => `${user.last_name}, ${user.first_name}`,
      sortable: true,
      wrap: true,
      cell: (user) => (
        <Blur columnKey="username">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip style={{ fontSize: "1em", textAlign: "center" }}>
                <Blur columnKey="username">{user.username}</Blur>
              </Tooltip>
            }
          >
            {user.ad_account ? (
              <span className="user-name">
                {user.last_name}, {user.first_name}
              </span>
            ) : canShowAddUserButton ? (
              // Clickable local user
              // eslint-disable-next-line
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleEditUser(user);
                }}
                className="user-name-link"
              >
                {user.last_name}, {user.first_name}
              </a>
            ) : (
              // Non-clickable AD user
              <span className="non-clickable-link user-name">
                {user.last_name}, {user.first_name}
              </span>
            )}
          </OverlayTrigger>
        </Blur>
      ),
    },

    ...(showAccessColumns
      ? menu
          .filter((category) =>
            ["reports", "dashboard"].includes(category.menu_type)
          )
          .map((category) => ({
            name: (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{`${category.title}`}</Tooltip>}
              >
                <span>{cleanUpTitle(category.title)}</span>
              </OverlayTrigger>
            ),
            sortable: true,
            wrap: true, // Enable text wrapping
            // Removed minWidth for dynamic sizing
            cell: (user) => getReportAccessIcon(user, category),
            sortFunction: (a, b) => accessSortFunction(a, b, category),
          }))
      : []),
    {
      name: (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>When the user last logged in</Tooltip>}
        >
          <span>Last Login</span>
        </OverlayTrigger>
      ),
      selector: (user) => user.last_login,
      sortable: true,
      wrap: true, // Enable text wrapping
      // Removed minWidth for dynamic sizing
      cell: (user) =>
        user.last_login
          ? new Date(user.last_login).toLocaleDateString()
          : "Never",
    },
    ...(canShowAddUserButton
      ? [
          {
            name: (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Remove AD User</Tooltip>}
              >
                <span>Remove AD User</span>
              </OverlayTrigger>
            ),
            sortable: false,
            wrap: true, // Enable text wrapping
            cell: (user) =>
              user.ad_account ? (
                <Button
                  variant="danger"
                  title="Remove AD User"
                  onClick={() => handleDeleteUser(user)}
                  className="remove-ad-user-button"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              ) : (
                <Button
                  variant="primary"
                  title="Cannot remove Local User"
                  disabled
                  className="remove-ad-user-button disabled-button"
                >
                  <FontAwesomeIcon icon={faBan} />
                </Button>
              ),
          },
        ]
      : []),
  ];

  if (loading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status" variant="primary" size="lg">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Container fluid className="umFluid">
      {/* Card Header */}
      <Row>
        <Col>
          <div className="card-header">
            {!userRole && (
              <div className="alert alert-danger" role="alert">
                <strong>Warning!</strong> Missing District Role. Please contact
                META support.
              </div>
            )}
            <h5>
              <span className="authText">
                {userAd ? "Authorized Users" : "My Account"}
              </span>
            </h5>
            <div className="userManagement-btns">
              {canShowAddUserButton && (
                <>
                  <Button
                    type="button"
                    className="btn btn-info"
                    onClick={() => navigate("/edituser")}
                    title="Add New User"
                  >
                    <FontAwesomeIcon icon={faUserPlus} className="umicon" />{" "}
                    <span className="addNewUserTxt">Add New User</span>
                  </Button>
                  <Button
                    type="button"
                    className="btn btn-info export-button"
                    onClick={exportToCSV}
                    title="Export Users List"
                  >
                    <FontAwesomeIcon icon={faFileExport} /> Export Users
                  </Button>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>

      {/* Search Bar and Toggle Button */}
      {userAd && (
        <>
          <Row className="my-3">
            <Col lg={11} md={8} sm={12}>
              <InputGroup className="mb-3 search-bar-user">
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <FormControl
                  placeholder={`Search ${selectedDistrict.name} Users`}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search-bar-user"
                />
                {searchTerm && (
                  <InputGroup.Text
                    onClick={handleClearSearch}
                    className="clear-search-btn"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </InputGroup.Text>
                )}
              </InputGroup>
            </Col>
            <Col lg={1} md={4} sm={12} className="d-flex align-items-center">
              <Button
                onClick={() => setShowAccessColumns((prev) => !prev)}
                variant="link"
                size="lg"
                title={
                  showAccessColumns
                    ? "Hide Access Columns"
                    : "Show Access Columns"
                }
                className="toggle-columns-button"
              >
                <FontAwesomeIcon
                  icon={showAccessColumns ? faToggleOn : faToggleOff}
                />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="table-wrapper">
                <DataTable
                  columns={columns}
                  data={filteredUsers}
                  pagination
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 25, 50, 100]}
                  paginationComponentOptions={{
                    rowsPerPageText: "Users per page:",
                  }}
                  
                  highlightOnHover
                  defaultSortField="Name"
                  sortIcon={<FontAwesomeIcon icon={faSortUp} />}
                  customStyles={customStyles}
                  striped
                  responsive
                  noHeader
                  className="user-management-table"
                />
                <div className="table-legend">
                  <h5 className="legend-header">Legend</h5>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ color: "green" }}
                      />{" "}
                      User has access to all reports
                    </li>
                    <li className="list-group-item">
                      <FontAwesomeIcon
                        icon={faFilter}
                        style={{ color: "#33A1FD" }}
                      />{" "}
                      User has partial access
                    </li>
                    <li className="list-group-item">
                      <FontAwesomeIcon icon={faBan} style={{ color: "red" }} />{" "}
                      No access
                    </li>
                    <li className="list-group-item">
                      User Name: Data Collector User
                    </li>
                    <li className="list-group-item">
                      {/* eslint-disable-next-line */}
                      <a href="#" className="local-user-link">
                        User Name
                      </a>
                      : Locally Created User
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}

      {/* Change Password Modal */}
      <Row>
        <Col>
          {!userAd && (
            <Button
              variant="secondary"
              onClick={() => setShowChangePasswordModal(true)}
              className="change-password-btn"
            >
              Change Password
            </Button>
          )}
          <ChangePassword
            show={showChangePasswordModal}
            handleClose={handleClose}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default UserManagement;
